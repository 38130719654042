import React from 'react';
import TeamMember from './TeamMember';

const Team = () => {
  return (
    <div className="container mt-5 ">
      <h2 className="text-center my-5 texto-equipo texto-blanco">Nuestro Equipo</h2>
      <div className="row mt-5 equipo">
        <div className="col-md-4">
          <TeamMember
            name="Alex Rojas Cáceres"
            role="Ingeniero en Prevención de Riesgos"
            imageSrc="img/alex1.jpeg"
            instagramLink="https://www.instagram.com/preverisomosprevencion/"
            linkedinLink="https://cl.linkedin.com/in/preveri-somos-prevenci%C3%B3n-4aa1b0272?trk=people-guest_people_search-card"
          />
        </div>
      </div>
    </div>
  );
};


export default Team;