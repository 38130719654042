import "./App.css";
import "./servicios.css";
import Servicios from "./components/Servicios";
import Equipo from "./components/Equipo";
import Cover from "./components/Cover";
import Contacto from "./components/Contacto";
import { Navbar, Nav } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function App() {
  const [showScroll, setShowScroll] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const contacto = {
    fontWeight: 'bold',
    color: 'white',
  };

  return (
    <div className="App">

      <div className="botonesBottom">

        {showScroll && (
          <button className="btn btn-warning btn-scroll-top " onClick={scrollTop}>
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
          
        )}

        <a className="btn btn-success btn-wps" href="https://wa.link/p44hbg" target="_blank" rel="noopener">
          <FontAwesomeIcon icon={faWhatsapp} size="lg" />
          </a>
      
      </div>  

      <Navbar expand="lg" className="responsive-nav header_area">
        <Navbar.Brand href="#home">
          <img
            src="https://img.freepik.com/iconos-gratis/casco_318-805172.jpg?w=2000"
            alt="Logo"
            className="logo"
          />
          <div className="coverbarra">
            <h3 className="titulo">PREVERI</h3>
            <h2 className="subtitulo">SOMOS PREVENCIÓN</h2>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end navbar-detail"
        >
          <Nav className="ml-auto">
            <Nav.Link href="#home">
              <p className="linkText">Inicio</p>
            </Nav.Link>
            <Nav.Link href="#servicios">
              <p className="linkText">Servicios</p>
            </Nav.Link>
            <Nav.Link href="#equipo">
              <p className="linkText">Equipo</p>
            </Nav.Link>
            <Nav.Link href="#contacto">
              <button className="btn btn-warning asd">Contacto</button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Cover />
      
      <section id="cover" className="land">
        <div class="columnas-flex">
          <div class="columna-izquierda">
            <div className="cover">
              <h3 className="titulo">GESTIONAMOS LA PREVENCIÓN DE RIESGO</h3>
              <h2 className="subtitulo">
                Deja en nuestras manos la seguridad de tu empresa y
                trabajadores.
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="servicios">
        <Servicios />
      </section>

      <section id="equipo" className="eq">
        <Equipo />
      </section>

      <section id="contacto" className="eq">
        <Contacto />
      </section>

      <footer className="futer">
        <i>© 2023 HedyDev v1.2</i>
      </footer>
    </div>
  );
}

export default App;
