import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faHardHat } from '@fortawesome/free-solid-svg-icons'

const Team = () => {
  return (


    <Container fluid className='contacto-container px-0'>
    <Row>
      <Col xs={12} md={6} className="d-flex align-items-center justify-content-center columna-izquierda">
        <div>
          <h3 className="titulo">PREVERI</h3>
          <h2 className="subtitulo">Somos Prevención</h2>
        </div>
      </Col>
      <Col xs={12} md={6} className="d-flex align-items-center justify-content-center columna-derecha">
        <div className='col-der-text'>
          <p><b>Escríbenos o llámanos para contactarnos:</b></p>
          <p><a href="mailto:contacto@miempresa.com"><FontAwesomeIcon icon={faHardHat} className='font'/>contacto@preveri.cl</a></p>
          <p><a href="https://wa.link/p44hbg">
              <FontAwesomeIcon icon={faWhatsapp} className='font'/>
              +56 9 4208 0289
              </a>
          </p>
        </div>
      </Col>
    </Row>
  </Container>
  );
};


export default Team;