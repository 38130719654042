import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

function Servicios() {
    return (
        <Container className='servicios'>
        <h2 className="text-center my-5 texto-blanco">Nuestros Servicios</h2>
        <Row>
          <Col md={4} className="mb-5">
            <Card className='card-service card-hover'>
              <Card.Body>
                <Card.Img variant="top" src={'img/1serviciosB.svg'} />
                <Card.Title>Asesorías en Prevención de Riesgos</Card.Title>
                <Card.Text>
                  Ofrecemos asesorías en prevención de riesgos para empresas y
                  organizaciones, con el objetivo de reducir accidentes laborales y
                  enfermedades profesionales.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-5">
            <Card className='card-service card-hover'>
              <Card.Body>
                <Card.Img variant="top" src={'img/2comitesB.svg'} />
                <Card.Title>Confección y Apoyo a Comités Paritarios</Card.Title>
                <Card.Text>
                  Contamos con un equipo de profesionales especializados en la
                  confección y apoyo a comités paritarios, con el objetivo de
                  promover la participación de los trabajadores en temas de
                  prevención de riesgos laborales.
                </Card.Text>
              </Card.Body>
            </Card >
          </Col>
          <Col md={4} className="mb-5">
            <Card className='card-service card-hover'>
              <Card.Body>
                <Card.Img variant="top" src={'img/3matrixB.svg'} />
                <Card.Title>
                  Creación de Procedimientos de Trabajo, Matriz de Riesgos
                </Card.Title>
                <Card.Text>
                  Desarrollamos procedimientos de trabajo y matrices de riesgos
                  personalizadas para cada empresa, con el fin de identificar y
                  prevenir los riesgos laborales.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-5">
            <Card className='card-service card-hover'>
              <Card.Body>
                <Card.Img variant="top" src={'img/4protocolos.svg'} />
                <Card.Title>Protocolos Minsal</Card.Title>
                <Card.Text>
                  Nos encargamos de la elaboración y actualización de protocolos
                  Minsal relacionados con plaguicidas, radiación UV, PREXOR, MMC,
                  TMERT, entre otros.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-5">
            <Card className='card-service card-hover'>
              <Card.Body>
                <Card.Img variant="top" src={'img/5certificaciones.svg'} />
                <Card.Title>Certificaciones Global Gap, Grasp</Card.Title>
                <Card.Text>
                  Ofrecemos servicios de asesoría y consultoría para la obtención de
                  certificaciones Global Gap y Grasp, garantizando el cumplimiento
                  de los estándares de calidad en la producción agrícola.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-5">
            <Card className='card-service card-hover'>
              <Card.Body>
                <Card.Img variant="top" src={'img/6descargos.svg'} />
                <Card.Title>Descargos por Sumarios o Fiscalizaciones</Card.Title>
                <Card.Text>
                  Brindamos asesoramiento y apoyo en descargos por sumarios o
                  fiscalizaciones laborales, con el objetivo de proteger los derechos
                  de nuestros clientes.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

export default Servicios;
