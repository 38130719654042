import { useCallback, useMemo } from "react";
import Particles from "react-tsparticles";
import { loadFull} from "tsparticles";
import { Container} from 'react-bootstrap';

export default function Cover() {
  const options = useMemo(() => {
    return {
      particles: {
        number: {
          value: 80, // Modifiqué el valor de 150 a 80
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: "#FFFFFF"
        },
        shape: {
          type: "polygon",
          "polygon": {
            "nb_sides": 5
          }
        },
        size: {
          value: 5,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 1,
            sync: false
          }
        },
        opacity: {
          value: 1,
          random: true,
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0,
            sync: false
          }
        },
        move: {
          enable: true,
          speed: 0.2, // Modifiqué el valor de 0.2 a 0.5
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: true,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detectsOn: "window",
        events: {
          onhover: {
            enable: true,
            mode: "grab"
          },
          onclick: {
            enable: true,
            mode: "push"
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 200,
            links: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            quantity: 4
          },
          remove: {
            quantity: 2
          }
        }
      }
    };
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return  <Container> <Particles id="tsparticles" options={options} init={particlesInit} 
                          ></Particles> </Container>;

}



