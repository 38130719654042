import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const TeamMember = ({ name, role, imageSrc, instagramLink, linkedinLink }) => {
  return (
    <Card style={{ borderRadius: "10px" }} className="card-hover">
      <Card.Img
        variant="top"
        src={imageSrc}
        style={{ borderRadius: "10px 10px 0 0", width:"200px", height:"220px"  }}
      />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{role}</Card.Subtitle>
        <Card.Text>
         Con más de 9 años de experiencia en la gestión de Prevención de Riesgos en empresas del rubro de Producción Animal, Agrícola y Minero.
        </Card.Text>
        <a href={instagramLink} className="btn btn-primary mr-2 fainsta">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        
        <a href={linkedinLink} className="btn btn-secondary">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </Card.Body>
    </Card>
  );
};

export default TeamMember;